.ninety {
	width : 90%;
}

.two-hundre {
	width : 200px;
}

body {
	scrollbar-width: none;
	-ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: -moz-scrollbars-none;
	text-rendering: geometricPrecision;
	transition: all .2s ease-in-out;
	overflow: visible;
}

.grow {
	transition: all .2s ease-in-out;
	transform: scale(1.05);
}

.anti-grow {
	transition: all .2s ease-in-out;
	transform: scale(1.00);
}